import createReactComponent from '../createReactComponent';
export default createReactComponent('message-forward', 'IconMessageForward', [
  [
    'path',
    {
      d: 'M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 8l3 3l-3 3', key: 'svg-1' }],
  ['path', { d: 'M16 11h-8', key: 'svg-2' }],
]);
