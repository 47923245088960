import createReactComponent from '../createReactComponent';
export default createReactComponent('receipt-dollar', 'IconReceiptDollar', [
  [
    'path',
    {
      d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14.8 8a2 2 0 0 0 -1.8 -1h-2a2 2 0 1 0 0 4h2a2 2 0 1 1 0 4h-2a2 2 0 0 1 -1.8 -1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 6v10', key: 'svg-2' }],
]);
